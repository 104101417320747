import {Col, Row} from "react-grid-system";
import React from "react";
import {CHART_TITLE_CELL_WIDTH, TITLE_CELL_WIDTH} from "./ComparePlansContent";
import {formatCurrency} from "../../utils/format";
import {PlanSummaryResponse} from "../models/PlanSummaryResponse";

interface EstimatedEstateTaxRowProps {
    ComparePlan1EstimatedEstateTax: number;
    ComparePlan2PlanSummaryResponse: PlanSummaryResponse;
    ComparePlan2EstimatedEstateTax: number;
}

const EstimatedEstateTaxRow: React.FC<EstimatedEstateTaxRowProps> = ({
                                                                         ComparePlan1EstimatedEstateTax,
                                                                         ComparePlan2EstimatedEstateTax,
                                                                         ComparePlan2PlanSummaryResponse
                                                                     }) => {
    return <Row style={{marginTop: 15}}>
        <Col width={TITLE_CELL_WIDTH} style={{paddingLeft: 0}}>
            <div className="compare-plans-sub-header" style={{marginTop: 15}}>Estimated Estate Tax</div>
        </Col>
        <span className="divider"></span>

        <Col md={CHART_TITLE_CELL_WIDTH}>
            <div className="plan-summary-card">
                <div style={{textAlign: "center", marginTop: 15}}>
                    <b>{formatCurrency(ComparePlan1EstimatedEstateTax)}</b>
                </div>
            </div>
        </Col>
        <span className="divider"></span>
        <Col md={CHART_TITLE_CELL_WIDTH}>
            {ComparePlan2PlanSummaryResponse != null &&
                <>
                    <div className="plan-summary-card">
                        <div style={{textAlign: "center", marginTop: 15}}>
                            <b>{formatCurrency(ComparePlan2EstimatedEstateTax ?? 0)}</b>
                        </div>
                    </div>
                </>
            }
        </Col>
    </Row>
}

export default EstimatedEstateTaxRow;