import {
    AnnualGiftingStrategy,
    EstimatedImpact,
    GiftToGrantorTrustStrategy,
    GRATStrategy,
    GRATEstimatedImpactRequestDTO,
    GRATRates,
    GrowthRates,
    PayoutPercentagesRequestDTO,
    PayoutPercentagesResponseDTO,
    StateEstateTransferRates,
    StateOfResidencyStrategy,
    StrategyDetailsSectionInfo,
    WealthPOSummaryResponse
} from "./WealthPOTypes";
import {get, post, put, remove} from "../core/api"

const extractBody = (res: Response) => res.json();
const isSuccess = (res: Response) => res.status === 200 || res.status === 201;

const getStateEstateTransferRatesReferenceData = (profileId: string): Promise<StateEstateTransferRates> => {
    return get(`/holistic-advice/profiles/${profileId}/wealthpo/strategy/referenceData/stateEstateTransferRates`)
        .then(extractBody);
}

const getGrowthRatesReferenceData = (profileId: string): Promise<GrowthRates> => {
    return get(`/holistic-advice/profiles/${profileId}/wealthpo/strategy/referenceData/growthRates`)
        .then(extractBody);
}

const getGRATRatesReferenceData = (profileId: string): Promise<GRATRates> => {
    return get(`/holistic-advice/profiles/${profileId}/wealthpo/strategy/referenceData/gratRates`)
        .then(extractBody)
}

const getPayoutPercentages = (profileId: string, payoutPercentagesRequestDTO: PayoutPercentagesRequestDTO): Promise<PayoutPercentagesResponseDTO> => {
    return post(`/holistic-advice/profiles/${profileId}/wealthpo/strategy/grat/payoutPercentages`, payoutPercentagesRequestDTO)
        .then(extractBody)
}

const getEstimatedImpactForSOR = (profileId: string, selectedStateOfResidency: string, abortSignal?: AbortSignal): Promise<EstimatedImpact> => {
    return get(
        `/holistic-advice/profiles/${profileId}/wealthpo/strategy/stateOfResidency/estimatedImpact/${selectedStateOfResidency}`,
        {signal: abortSignal}
    ).then(extractBody);
}

const getEstimatedImpactForAnnualGifting = (profileId: string, requestBody: AnnualGiftingStrategy, abortSignal?: AbortSignal)
    : Promise<EstimatedImpact> => {
    return post(`/holistic-advice/profiles/${profileId}/wealthpo/strategy/annualGifting/estimatedImpact`,
        requestBody,
        {signal: abortSignal}
    ).then(extractBody);
}

const getEstimatedImpactForGiftToGrantorTrust = (profileId: string, requestBody: GiftToGrantorTrustStrategy, abortSignal?: AbortSignal)
    : Promise<EstimatedImpact> => {
    return post(`/holistic-advice/profiles/${profileId}/wealthpo/strategy/giftToGrantorTrust/estimatedImpact`,
        requestBody,
        {signal: abortSignal}
    ).then(extractBody);
}

const getEstimatedImpactForGRAT = (profileId: string, requestBody: GRATEstimatedImpactRequestDTO, abortSignal?: AbortSignal): Promise<EstimatedImpact> => {
    return post(`/holistic-advice/profiles/${profileId}/wealthpo/strategy/grat/estimatedImpact`,
        requestBody,
        {signal: abortSignal}
    ).then(extractBody);
}

const saveStateOfResidencyStrategy = (profileId: string, requestBody: StateOfResidencyStrategy) => {
    return post(`/holistic-advice/profiles/${profileId}/wealthpo/strategy/stateOfResidency`, requestBody)
        .then(isSuccess);
}

const editStateOfResidencyStrategy = (profileId: string, requestBody: StateOfResidencyStrategy) => {
    return put(`/holistic-advice/profiles/${profileId}/wealthpo/strategy/stateOfResidency`, requestBody)
        .then(isSuccess);
}

const deleteStateOfResidencyStrategy = (profileId: string, strategyId: string) => {
    return remove(`/holistic-advice/profiles/${profileId}/wealthpo/strategy/stateOfResidency/${strategyId}`)
        .then(isSuccess)
}

const deleteStrategy = (profileId: string, strategyId: string) => {
    return remove(`/holistic-advice/profiles/${profileId}/wealthpo/strategy/delete/${strategyId}`)
        .then(isSuccess)
}

const createAnnualGiftingStrategy = (profileId: string, requestBody: AnnualGiftingStrategy) => {
    return post(`/holistic-advice/profiles/${profileId}/wealthpo/strategy/annualGifting`, requestBody)
        .then(isSuccess);
}

const editAnnualGiftingStrategy = (profileId: string, requestBody: AnnualGiftingStrategy) => {
    return put(`/holistic-advice/profiles/${profileId}/wealthpo/strategy/annualGifting`, requestBody)
        .then(isSuccess);
}

const createGiftToGrantorTrustStrategy = (profileId: string, requestBody: GiftToGrantorTrustStrategy) => {
    return post(`/holistic-advice/profiles/${profileId}/wealthpo/strategy/giftToGrantorTrust`, requestBody)
        .then(isSuccess);
}

const editGiftToGrantorTrustStrategy = (profileId: string, requestBody: GiftToGrantorTrustStrategy) => {
    return put(`/holistic-advice/profiles/${profileId}/wealthpo/strategy/giftToGrantorTrust`, requestBody)
        .then(isSuccess);
}

const createGRATStrategy = (profileId: string, requestBody: GRATStrategy) => {
    return post(`/holistic-advice/profiles/${profileId}/wealthpo/strategy/grat`, requestBody)
        .then(isSuccess);
}

const editGRATStrategy = (profileId: string, requestBody: GRATStrategy) => {
    return put(`/holistic-advice/profiles/${profileId}/wealthpo/strategy/grat`, requestBody)
        .then(isSuccess);
}

const editStrategyEnabledStatus = (profileId: string, requestBody: StrategyDetailsSectionInfo) => {
    return put(`/holistic-advice/profiles/${profileId}/wealthpo/strategy/updateEnabledStatus`, requestBody)
        .then(isSuccess);
}

const getWealthPOSummary = (profileId: string): Promise<WealthPOSummaryResponse> => {
    return get(`/holistic-advice/profiles/${profileId}/wealthpo/strategy/summary`)
        .then(extractBody);
}

export const wealthPOApiClient = {
    getStateEstateTransferRatesReferenceData,
    getGrowthRatesReferenceData,
    getGRATRatesReferenceData,
    getPayoutPercentages,
    getEstimatedImpactForSOR,
    getEstimatedImpactForAnnualGifting,
    getEstimatedImpactForGiftToGrantorTrust,
    getEstimatedImpactForGRAT,
    saveStateOfResidencyStrategy,
    editStateOfResidencyStrategy,
    editStrategyEnabledStatus,
    createAnnualGiftingStrategy,
    editAnnualGiftingStrategy,
    createGiftToGrantorTrustStrategy,
    editGiftToGrantorTrustStrategy,
    createGRATStrategy,
    editGRATStrategy,
    deleteStrategy,
    deleteStateOfResidencyStrategy,
    getWealthPOSummary
}


