import moment from "moment"

export type RevocableTrust = {
    flowchartName:  string,
    legalName: string,
    nickName: string | null,
    trustCreationDate: string,
    recordOfAmendment: string | null
    revTrustId: string,
    grantors: Trustee[],
    trustees: Trustee[],
    successors : Trustee[],
    funding: string | null,
    investmentAdvisor: string | null,
    trustProtector: string | null,
    trustJurisdiction: string | null
}

export type TrustDetails = Trustee[];

export enum TrusteeType {
    Grantor = "GRANTOR",
    Trustee = "TRUSTEE",
    Successor = "SUCCESSOR_TRUSTEE"
}
export type Trustee = {
    revTrustId:  string | undefined,
    memberId?: string | null,
    memberType: TrusteeType,
    memberOrder?: number,
    customName?: string,
    familyMember: boolean
}

export type Member = {
    value: string, 
    label: string,
    selected: boolean
}

export type FamilyMembers = {
	value: string,
    label: string,
	familyMember: Member[]
}


/**
 *There can be more than 1 flowchart per Profile; Each flowchart will have one rev/irrev x
 */
export type EstateFlowchartState = {
    flowchartId: string | undefined
    revocableTrust: RevocableTrust
}

export type TrustValidation = {
    revocableTrust: null | RevocableTrustValidation,
}

export type RevocableTrustValidation = null | {
    flowchartName: string | null,
    legalName: string | null
}

export const initialRevocableTrust: RevocableTrust = {
    flowchartName: '',
    legalName: '',
    nickName: '',
    trustCreationDate: moment().toISOString(),
    recordOfAmendment: '',
    revTrustId: '',
    grantors: [],
    trustees: [],
    successors: [],
    funding: '',
    investmentAdvisor: '',
    trustProtector: '',
    trustJurisdiction: ''
}

export const initialEstateFlowchartState: EstateFlowchartState = {
    flowchartId: undefined,
    revocableTrust: initialRevocableTrust
};