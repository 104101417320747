import {post, get, put} from "../core/api";
import {RevocableTrust} from "./EstateFlowchartTypes";


const isSuccess: (response: Response) => boolean = ({status}) => status === 200 || status === 201;

const extractBody = (res: Response) => res.json();


export interface ResponseStatus {
    traceId: string;
    isSuccessStatusCode: boolean;
}

function mapResponseStatus(response: Response, statusCodeValidator?: (response: Response) => boolean) {
    const responseStatus: ResponseStatus = {
        traceId: response.headers.get('trace-id')!,
        isSuccessStatusCode: statusCodeValidator ? statusCodeValidator(response) : false,
    }
    return responseStatus;
}

const createRevocableTrust = (profileId: string, requestBody: RevocableTrust)=> {
    return post(`/holistic-advice/profiles/${profileId}/estateFlowchart/revocableTrust`, requestBody)
        .then((response: Response) => mapResponseStatus(response, isSuccess))
        .catch((response: Response) => mapResponseStatus(response));
}

const getFlowchart = (profileId: string) => {
    return get(`/holistic-advice/profiles/${profileId}/estateFlowchart`)
        .then(extractBody);
}

const editRevocableTrust = (profileId: string, flowchartId: string, requestBody: RevocableTrust)=> {
    return put(`/holistic-advice/profiles/${profileId}/estateFlowchart/${flowchartId}`, requestBody)
        .then((response: Response) => mapResponseStatus(response, isSuccess))
        .catch((response: Response) => mapResponseStatus(response));
}

export const estateFlowchartApiClient = {
    createRevocableTrust,
    getFlowchart,
    editRevocableTrust
}