import {Col, Row} from "react-grid-system";
import {BarchartUnderlay} from "../../ClientManagement/AssetReliance/BarChart/BarChartUnderlay";
import React from "react";
import {hasPositiveExcessAssets} from "../../ClientManagement/AssetReliance/AssetRelianceUtil";
import ChartStatusLine from "../../ClientManagement/AssetReliance/ChartStatusLine";
import {COLOR_GOALS, COLOR_NT_ORANGE_800} from "../../constants/colors";
import {StackedBarChart} from "../../components";
import ExcessAssetsVerticalLine from "../../ClientManagement/AssetReliance/ExcessAssetsVerticalLine";
import _ from "lodash";
import {roundUpToNext} from "../../utils/format";
import ComparePlanAssetBarChartColumn
    from "../../ClientManagement/AssetReliance/BarChart/ComparePlanAssetBarChartColumn";
import {CHART_CELL_WIDTH, TITLE_CELL_WIDTH} from "./ComparePlansContent";

type ComparePlansBarchartContainerProps = {
    netAssets: number,
    barChartColWidth: number,
    totalStacks: number,
    totalGoals: number,
    excessAssets: number,
    proposalNetAssets: number,
    proposalTotalGoals: number,
    proposalExcessAssets: number
}

export const ComparePlansBarchartContainer: React.FC<ComparePlansBarchartContainerProps> = ({
                                                                                                netAssets,
                                                                                                barChartColWidth,
                                                                                                totalStacks,
                                                                                                totalGoals,
                                                                                                excessAssets,
                                                                                                proposalNetAssets,
                                                                                                proposalTotalGoals,
                                                                                                proposalExcessAssets
                                                                                            }) => {
    const minimumValueToApplyGraphRounding = 1_000_000;
    let barchartMaxValueForProfile = totalGoals >= netAssets ? totalGoals : netAssets;
    let barchartMaxValueForProposal = proposalTotalGoals >= proposalNetAssets ? proposalTotalGoals : proposalNetAssets;

    const chartUnderlayMaxValue = (Math.max(barchartMaxValueForProfile, barchartMaxValueForProposal) >= minimumValueToApplyGraphRounding)
        ? roundUpToNext(Math.max(barchartMaxValueForProfile, barchartMaxValueForProposal), 5)
        : minimumValueToApplyGraphRounding;

    return (
        <>
            <Row>
                <Col className="asset-reliance-chart-container">
                    <BarchartUnderlay totalStacksCount={totalStacks}
                                      width={barChartColWidth}
                                      maxValue={chartUnderlayMaxValue}
                                      titleCellWidth={TITLE_CELL_WIDTH}
                                      stackContainerWidth={CHART_CELL_WIDTH}
                                      numberOfColumns={6}
                                      minimumColumn={0}
                                      rightBorderDivider={true}
                                      className = "asset-reliance-barchart-row compare-plans-barchart-row-underlay"
                    />
                    <span className="divider"></span>
                    <Row data-testid="AssetRelianceBarchartRow"
                         className="asset-reliance-barchart-row"
                         style={{height: "100%"}}>
                        <Col className="asset-reliance-chart-container">
                            {_.range(Math.ceil(totalStacks / 2)).map((index) => {
                                const isAssetSufficient: boolean = hasPositiveExcessAssets(excessAssets);
                                const assetBarChart = new ComparePlanAssetBarChartColumn(netAssets, totalGoals, excessAssets, chartUnderlayMaxValue, isAssetSufficient)
                                const columnHeightPercentage = (assetBarChart.getColumnMaxValue() / chartUnderlayMaxValue * 100).toFixed(2);

                                return <React.Fragment key={`ExcludedAssetBarChart` + index}>
                                    <Col style={{
                                        width: `${CHART_CELL_WIDTH}px`,
                                        height: `${columnHeightPercentage}%`,
                                        alignSelf: "flex-end"
                                    }}
                                    >
                                        <ChartStatusLine label=''
                                                         width={`${CHART_CELL_WIDTH}px`}
                                                         verticalOffset={assetBarChart.getGoalsPositionVerticalOffset()}
                                                         lineColor={COLOR_GOALS}
                                                         marginLeft={140}
                                                         textColor={COLOR_NT_ORANGE_800}
                                                         id={"barChartOverlay"}
                                                         isAssetReliance={true}
                                        />
                                        <div data-testid={"AssetRelianceStackBarchart"}
                                             className="compareplan_barchart-container">
                                            <StackedBarChart
                                                showLabels={false}
                                                height = {columnHeightPercentage}
                                                top={`${100 - parseFloat(columnHeightPercentage)}`}
                                                chartWidth={CHART_CELL_WIDTH}
                                                className="compare-plans-asset-bar-chart"
                                                data={assetBarChart.getChartItems()}
                                            />
                                            <ExcessAssetsVerticalLine color={assetBarChart.getPillColor()}
                                                                      bottomPercentage={assetBarChart.getVerticalLineMargins().bottomMargin}
                                                                      excessAssets={excessAssets}
                                                                      topPercentage={assetBarChart.getVerticalLineMargins().topMargin}
                                                                      classname={"compare-plans-asset-line-stack-one"}
                                            />
                                        </div>
                                    </Col>
                                </React.Fragment>
                            })}
                        </Col>
                        <Col className="asset-reliance-chart-container">
                            {_.range(Math.ceil(totalStacks / 2), totalStacks).map((index) => {
                                const isAssetSufficient: boolean = hasPositiveExcessAssets(proposalExcessAssets);
                                const assetBarChart = new ComparePlanAssetBarChartColumn(proposalNetAssets, proposalTotalGoals, proposalExcessAssets, chartUnderlayMaxValue, isAssetSufficient)
                                const columnHeightPercentage = (assetBarChart.getColumnMaxValue() / chartUnderlayMaxValue * 100).toFixed(2);

                                return <React.Fragment key={`ExcludedAssetBarChart` + index}>
                                    <Col style={{width: `${CHART_CELL_WIDTH}px`, height: `${columnHeightPercentage}%`, alignSelf: "flex-end"}}>
                                        <ChartStatusLine label=''
                                                         width={`${CHART_CELL_WIDTH}px`}
                                                         verticalOffset={assetBarChart.getGoalsPositionVerticalOffset()}
                                                         lineColor={COLOR_GOALS}
                                                         marginLeft={-60}
                                                         textColor={COLOR_NT_ORANGE_800}
                                                         id={"barChartOverlay"}
                                                         isAssetReliance={true}
                                        />
                                        <div data-testid={"AssetRelianceStackBarchart"}
                                             className="compareplan_barchart-container">
                                            <StackedBarChart
                                                showLabels={false}
                                                height = {columnHeightPercentage}
                                                top={`${100 - parseFloat(columnHeightPercentage)}`}
                                                chartWidth={CHART_CELL_WIDTH}
                                                data={assetBarChart.getChartItems()}
                                            />
                                            <ExcessAssetsVerticalLine color={assetBarChart.getPillColor()}
                                                                      bottomPercentage={assetBarChart.getVerticalLineMargins().bottomMargin}
                                                                      excessAssets={proposalExcessAssets}
                                                                      topPercentage={assetBarChart.getVerticalLineMargins().topMargin}
                                                                      classname={"compare-plans-asset-line"}
                                            />
                                        </div>
                                    </Col>
                                </React.Fragment>
                            })}
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    )
}