import {ColumnCounter, TableCell, TableDisplay, TableRow} from "../../components";
import {formatCurrencyRounded} from "../../utils/format";


export class NetAssetsTableDisplayForComparePlan extends TableDisplay<number, TableRow<number>> {
    private readonly netAssets: number;
    private readonly proposalNetAssets: number;

    constructor(columnCounter: ColumnCounter, netAssets: number, proposalNetAssets: number) {
        super(columnCounter);
        this.netAssets = netAssets;
        this.proposalNetAssets = proposalNetAssets;
    }

    protected get headerLabel(): string {
        return "Net Assets";
    }

    protected get headerValues(): TableCell<number>[] {
        return [{
            originalValue: this.netAssets,
            renderedValue: formatCurrencyRounded(this.netAssets,0),
            isBlank: false
        },{
            originalValue: this.proposalNetAssets,
            renderedValue: formatCurrencyRounded(this.proposalNetAssets,0),
            isBlank: false

        }];
    }

    get rows(): TableRow<number>[] {
        return [{
            accentColor: '',
            children: [],
            label: "Total Present Value",
            uniqueIdentifier: 'total-present-value',
            values: [{
                originalValue: this.netAssets,
                renderedValue: this.netAssets?.toString(),
                isBlank: true
            }, {
                originalValue: this.proposalNetAssets,
                renderedValue: this.proposalNetAssets?.toString(),
                isBlank: true
            }]
        }];
    }
}