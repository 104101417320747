import React, {useCallback, useEffect, useState} from "react";
import {Col, Row} from "react-grid-system";
import BarChartLegend, {barChartLegend} from "../../components/Legend/BarChartLegend";
import {Dropdown, DropdownItem, FixedColumnCounter} from "../../components";
import useProfileAndProposals from "../../hooks/useProfileAndProposals";
import {useParams} from "react-router-dom";
import {RouteWithId} from "../../routes/types";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {getPlanSummary} from "../PlanSummary/planSummarySlice";
import {getRefWidth} from "../../ClientManagement/AssetReliance/AssetRelianceUtil";
import {ComparePlansBarchartContainer} from "./ComparePlansBarchartContainer";
import {GoalsTableDisplayForComparePlan} from "./GoalsTableDisplayForComparePlan";
import {NetAssetsTableDisplayForComparePlan} from "./NetAssetsDisplayTableForComparePlans";
import {ExcessAssetsDisplayTableForComparePlans} from "./ExcessAssetsDisplayTableForComparePlans";
import LoadingIndicator from "../../pages/LoadingIndicator";
import {AccordionTableHeaderOnly} from "../../components/AccordionTable/AccordionTableHeaderOnly";
import {PlanSummaryResponse} from "../models/PlanSummaryResponse";
import {
    COLOR_ASSET_SHORTFALL_ACCENT,
    COLOR_ASSETS_ACCOUNTS,
    COLOR_EXCESS_ASSETS_ACCENT,
    COLOR_GOALS
} from "../../constants/colors";
import "../../styles/pages/_asset-reliance-barchart.scss";
import {emptyInvestorGroup, InvestorGroupType} from "../../ClientManagement/models/InvestorGroupType";
import {clientManagementApiClient} from "../../ClientManagement/ClientManagementApiClient";
import Highcharts from "highcharts";
import {calculateCNWInEstateTotalValue} from "../../Assets/AssetSummary/common/AssetSummaryCalculator";
import {CreatePlanSummaryNetWorthOverTimeGraphOptions} from "../PlanSummary/PlanSummaryNetWorthOverTimeGraphUtils";
import {AssetsSummary, emptyAssetsSummary} from "../../Assets/models/Assets";
import {assetsApiClient} from "../../Assets/AssetsApiClient";
import NetWorthOverTimeRow from "./NetWorthOverTimeRow";
import {selectClientAssets, setClientAssets} from "../../Assets/clientAssetsSlice";
import ComparePlansRiskDonut from "./ComparePlansRiskDonut";
import ReserveTargetLengthRow from "./ReserveTargetLengthRow";
import {emptyEstateSummary, EstateSummary, EstateType} from "../../WealthTransfer/models/api";
import {wealthTransferApiClient} from "../../WealthTransfer/WealthTransferApiClient";
import EstimatedEstateTaxRow from "./EstimatedEstateTaxRow";

const ComparePlansContent: React.FC = () => {
    const dispatch = useAppDispatch();
    const {id} = useParams<RouteWithId>();
    const {proposals, approvedProfile} = useProfileAndProposals(id);
    const [barChartColWidth, setBarChartColWidth] = useState<number>(0);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [comparePlan1ProposalPlanSummaryResponse, setComparePlan1ProposalPlanSummaryResponse] = useState<PlanSummaryResponse>();
    const [comparePlan2ProposalPlanSummaryResponse, setComparePlan2ProposalPlanSummaryResponse] = useState<PlanSummaryResponse | null>(null);
    const [comparePlan1selectedProposalId, setComparePlan1selectedProposalId] = useState<string | null>(null);
    const [comparePlan2SelectedProposalId, setComparePlan2SelectedProposalId] = useState<string | null>(null);
    const [comparePlan1investorGroup, setComparePlan1investorGroup] = useState<InvestorGroupType>(emptyInvestorGroup);
    const [comparePlan2InvestorGroup, setComparePlan2InvestorGroup] = useState<InvestorGroupType>(emptyInvestorGroup);
    const clientAssets: AssetsSummary | undefined = useAppSelector(selectClientAssets);
    const [comparePlan2ClientAssets, setComparePlan2ClientAssets] = useState<AssetsSummary | undefined>(emptyAssetsSummary);
    const [comparePlan1PortfolioReserveTargetLength, setComparePlan1PortfolioReserveTargetLength] = useState<number | undefined>(undefined);
    const [comparePlan2PortfolioReserveTargetLengthForProposal, setComparePlan2PortfolioReserveTargetLengthForProposal] = useState<number | undefined>(undefined);
    const [comparePlan1EstateSummary, setComparePlan1EstateSummary] = useState<EstateSummary>(emptyEstateSummary);
    const [comparePlan2EstateSummaryForProposal, setComparePlan2EstateSummaryForProposal] = useState<EstateSummary>(emptyEstateSummary);

    const chartLegend = <div>
        <div className="compare-plans-sub-header">Excess Assets</div>
        <div className="bar-chart-legend-container">
            <div className="asset-reliance-barchart-legend-label">
                <BarChartLegend legend={barChartLegend.NET_ASSETS} label={"ASSETS"}/>
            </div>
            <div className="asset-reliance-barchart-legend-label">
                <BarChartLegend legend={barChartLegend.GOALS} label={"GOALS"}/>
            </div>
            <div className="asset-reliance-barchart-legend-label">
                <BarChartLegend legend={barChartLegend.ASSET_SHORTFALL} label={"ASSET SHORTFALL"}/>
            </div>
            <div className="asset-reliance-barchart-legend-label">
                <BarChartLegend legend={barChartLegend.EXCESS_ASSETS} label={"EXCESS ASSETS"}/>
            </div>
        </div>
    </div>;

    const fetchData = useCallback(async (proposalId: string) => {
        setIsLoading(true);
        const response = await dispatch(getPlanSummary(proposalId));
        if (response.payload) {
            const [
                newInvestorGroup,
                assetSummaryResponse,
                estateSummaryResponse,
                profileResponse
            ] = await Promise.all([
                clientManagementApiClient.getInvestorGroup(proposalId),
                assetsApiClient.getAssetsSummary(proposalId),
                wealthTransferApiClient.getEstateSummary(proposalId, EstateType.CURRENT),
                clientManagementApiClient.getProfile(proposalId)
            ]);
            setComparePlan1investorGroup(newInvestorGroup);
            dispatch(setClientAssets(assetSummaryResponse));
            setComparePlan1EstateSummary(estateSummaryResponse);
            setComparePlan1PortfolioReserveTargetLength(profileResponse.portfolioReserveTargetLength);
            setComparePlan1ProposalPlanSummaryResponse(response.payload as PlanSummaryResponse);
            setIsLoading(false);
        }
    }, [dispatch]);

    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            fetchData(comparePlan1selectedProposalId ?? id);
        }
        return () => {
            isMounted = false;
        };
    }, [comparePlan1selectedProposalId, id, fetchData]);

    useEffect(() => {
        let isMounted = true;
        (async () => {
            if (comparePlan2SelectedProposalId != null) {
                setIsLoading(true);
                const response = await dispatch(getPlanSummary(comparePlan2SelectedProposalId));
                if (isMounted && response.payload) {
                    Promise.all([
                        clientManagementApiClient.getInvestorGroup(comparePlan2SelectedProposalId),
                        assetsApiClient.getAssetsSummary(comparePlan2SelectedProposalId),
                        wealthTransferApiClient.getEstateSummary(comparePlan2SelectedProposalId, EstateType.CURRENT),
                        clientManagementApiClient.getProfile(comparePlan2SelectedProposalId)
                    ]).then(([newInvestorGroup,
                                 assetSummaryResponse,
                                 estateSummaryResponse,
                                 proposalResponse]) => {
                        setComparePlan2InvestorGroup(newInvestorGroup);
                        setComparePlan2ClientAssets(assetSummaryResponse);
                        setComparePlan2EstateSummaryForProposal(estateSummaryResponse);
                        setComparePlan2PortfolioReserveTargetLengthForProposal(proposalResponse.portfolioReserveTargetLength);
                        setIsLoading(false);
                    });
                    setComparePlan2ProposalPlanSummaryResponse(response.payload as PlanSummaryResponse);
                }
            }
        })();
        return () => {
            isMounted = false;
        };
    }, [comparePlan2SelectedProposalId]);

    if (isLoading) {
        return <LoadingIndicator/>
    }

    const netAssets = (comparePlan1ProposalPlanSummaryResponse!.totalNetValue ?? 0) + (comparePlan1ProposalPlanSummaryResponse!.totalAssetPurchaseValue ?? 0);
    const totalGoals = comparePlan1ProposalPlanSummaryResponse!.goalsTotalPresentValue ?? 0;
    const excessAssets = comparePlan1ProposalPlanSummaryResponse!.excessAssets;
    const proposalNetAssets = (comparePlan2ProposalPlanSummaryResponse?.totalNetValue ?? 0) + (comparePlan2ProposalPlanSummaryResponse?.totalAssetPurchaseValue ?? 0);
    const proposalTotalGoals = comparePlan2ProposalPlanSummaryResponse?.goalsTotalPresentValue ?? 0;
    const proposalExcessAssets = comparePlan2ProposalPlanSummaryResponse?.excessAssets ?? 0;

    const goalsTableDisplay = new GoalsTableDisplayForComparePlan(
        new FixedColumnCounter(2),
        totalGoals,
        proposalTotalGoals
    );

    const netAssetsTableDisplay = new NetAssetsTableDisplayForComparePlan(
        new FixedColumnCounter(2),
        netAssets,
        proposalNetAssets
    );

    const excessAssetsTableDisplay = new ExcessAssetsDisplayTableForComparePlans(
        new FixedColumnCounter(2),
        excessAssets,
        proposalExcessAssets
    );

    const totalTaxLiabilitiesForAllAccounts = clientAssets!.totalTaxLiabilities.totalPresentTaxLiabilityForAllAccounts ?? 0;
    const comparePlan1NetWealthAtCurrentAge = calculateCNWInEstateTotalValue(clientAssets!) + totalTaxLiabilitiesForAllAccounts;
    const comparePlan1GraphOptions: Highcharts.Options = CreatePlanSummaryNetWorthOverTimeGraphOptions({
        investorGroup: comparePlan1investorGroup,
        netWealthAtCurrentAge: comparePlan1NetWealthAtCurrentAge,
        futureValueOfExcessAssetsAtAge: comparePlan1ProposalPlanSummaryResponse!.futureValueOfExcessAssetsByAge,
    });
    const comparePlan2TotalTaxLiabilitiesForAllAccounts = comparePlan2ClientAssets!.totalTaxLiabilities.totalPresentTaxLiabilityForAllAccounts ?? 0;
    const comparePlan2NetWealthAtCurrentAge = calculateCNWInEstateTotalValue(comparePlan2ClientAssets!) + comparePlan2TotalTaxLiabilitiesForAllAccounts;
    const comparePlan2GraphOptions: Highcharts.Options = CreatePlanSummaryNetWorthOverTimeGraphOptions({
        investorGroup: comparePlan2InvestorGroup,
        netWealthAtCurrentAge: comparePlan2NetWealthAtCurrentAge,
        futureValueOfExcessAssetsAtAge: comparePlan2ProposalPlanSummaryResponse?.futureValueOfExcessAssetsByAge ?? [],
    });
    const renderDropdownItems = (comparePlanProposals: any[]) => {
        return comparePlanProposals.map(proposal => (
            <DropdownItem key={proposal.id} value={proposal.id}
                          itemText={proposal.displayName}
                          style={{paddingLeft: '35px'}}
            />
        ));
    };

    return (
        <main className="compare-plans-content main-container">
            <Row style={{marginBottom: 25}}>
                <Col width={TITLE_CELL_WIDTH}>
                    {chartLegend}
                </Col>
                <span className="divider"></span>
                <Col md={CHART_TITLE_CELL_WIDTH}
                     ref={(col: Col & HTMLDivElement | null) => {
                         const width = getRefWidth(col);
                         setBarChartColWidth(width);
                     }}
                >
                    <div data-testid="select-compare-plan1-label">
                        <Dropdown
                            className="compare-plans-dropdown"
                            label="Select a plan"
                            inlineLabel
                            panelHeight="auto"
                            panelWidth="300px"
                            size='medium'
                            value={comparePlan1selectedProposalId ?? id}
                            onChange={(e) => setComparePlan1selectedProposalId(e.value)}>
                            <DropdownItem key={approvedProfile.id} value={approvedProfile.id}
                                          itemText={approvedProfile.displayName}
                                          style={{paddingLeft: '35px'}}
                            />
                            {renderDropdownItems(proposals)}
                        </Dropdown>
                    </div>
                </Col>
                <span className="divider"></span>
                <Col md={CHART_TITLE_CELL_WIDTH}>
                    <div data-testid="select-compare-plan2-label">
                        <Dropdown
                            className="compare-plans-dropdown"
                            label="Select a plan"
                            inlineLabel
                            panelHeight="auto"
                            panelWidth="300px"
                            size='medium'
                            disabled={proposals.length === 0}
                            value={comparePlan2SelectedProposalId}
                            onChange={(e) => setComparePlan2SelectedProposalId(e.value)}>
                            <DropdownItem key={approvedProfile.id} value={approvedProfile.id}
                                          itemText={approvedProfile.displayName}
                                          style={{paddingLeft: '35px'}}
                            />
                            {renderDropdownItems(proposals)}
                        </Dropdown>
                    </div>
                </Col>
            </Row>
            <ComparePlansBarchartContainer netAssets={netAssets}
                                           barChartColWidth={barChartColWidth}
                                           totalStacks={comparePlan2ProposalPlanSummaryResponse != null ? 2 : 1}
                                           totalGoals={totalGoals}
                                           excessAssets={excessAssets}
                                           proposalNetAssets={proposalNetAssets}
                                           proposalTotalGoals={proposalTotalGoals}
                                           proposalExcessAssets={proposalExcessAssets}
            />
            <div className="margintop-xxxl">
                <AccordionTableHeaderOnly accordionTableId={"compare-plans-assets"}
                                          collapsedAccentColor={COLOR_ASSETS_ACCOUNTS}
                                          ariaLabel={"Compare-Plans-Assets"}
                                          titleCellWidth={TITLE_CELL_WIDTH}
                                          tableDisplayData={netAssetsTableDisplay}
                                          valueColumnSize={4}

                />
            </div>
            <div>
                <AccordionTableHeaderOnly accordionTableId={"compare-plans-goals"}
                                          collapsedAccentColor={COLOR_GOALS}
                                          ariaLabel={"Compare-Plans-Goals"}
                                          titleCellWidth={TITLE_CELL_WIDTH}
                                          tableDisplayData={goalsTableDisplay}
                                          valueColumnSize={4}

                />
            </div>
            <div>
                <AccordionTableHeaderOnly accordionTableId={"compare-plans-excess-assets"}
                                          collapsedAccentColor={excessAssets < 0
                                          && proposalExcessAssets <= 0 ? COLOR_ASSET_SHORTFALL_ACCENT
                                              : COLOR_EXCESS_ASSETS_ACCENT}
                                          ariaLabel={"compare-plans-excess-assets-row"}
                                          titleCellWidth={TITLE_CELL_WIDTH}
                                          tableDisplayData={excessAssetsTableDisplay}
                                          valueColumnSize={4}
                />
            </div>
            <NetWorthOverTimeRow
                comparePlan1PlanSummaryResponse={comparePlan1ProposalPlanSummaryResponse!}
                comparePlan2PlanSummaryResponse={comparePlan2ProposalPlanSummaryResponse!}
                comparePlan1GraphOptions={comparePlan1GraphOptions}
                comparePlan2GraphOptions={comparePlan2GraphOptions}
            />
            <hr style={{marginLeft: -15}}/>
            <ComparePlansRiskDonut
                comparePlan1PlanSummaryResponse={comparePlan1ProposalPlanSummaryResponse!}
                comparePlan2PlanSummaryResponse={comparePlan2ProposalPlanSummaryResponse!}
            />
            <hr style={{marginLeft: -15}}/>
            <ReserveTargetLengthRow
                comparePlan1PortfolioReserveTargetLength={comparePlan1PortfolioReserveTargetLength}
                comparePlan2PortfolioReserveTargetLength={comparePlan2PortfolioReserveTargetLengthForProposal}
                comparePlan2PlanSummaryResponse={comparePlan2ProposalPlanSummaryResponse!}
            />
            <hr style={{marginLeft: -15}}/>
            <EstimatedEstateTaxRow
                ComparePlan1EstimatedEstateTax={comparePlan1EstateSummary.estimatedEstateTax}
                ComparePlan2PlanSummaryResponse={comparePlan2ProposalPlanSummaryResponse!}
                ComparePlan2EstimatedEstateTax={comparePlan2EstateSummaryForProposal.estimatedEstateTax}
            />
        </main>
    )
}

export default ComparePlansContent;
export const TITLE_CELL_WIDTH = 374;
export const CHART_CELL_WIDTH = 370;
export const CHART_TITLE_CELL_WIDTH = 4;