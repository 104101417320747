import React from "react";
import {Route, Switch, useParams} from "react-router-dom";
import {RouteWithId} from "../routes/types";
import ReviewFiduciariesForm from "./ReviewFiduciariesForm";
import EstateFlowchart from "./EstateFlowchart";
import RevocableTrustForm from "./RevocableTrustForm";
import BeneficiariesFirstDeathContent from "./components/BenefeciariesFirstDeathContent";

const EstateFlowchartMain = () => {
    const {id} = useParams<RouteWithId>();

    return (
        <Switch>
            <Route exact path="/Profile/:id/ClientProfile/EstateFlowchart">
                <EstateFlowchart id={id}/>
            </Route>
            <Route path="/Profile/:id/ClientProfile/EstateFlowchart/ReviewFiduciaries">
                <ReviewFiduciariesForm/>
            </Route>
            <Route path="/Profile/:id/ClientProfile/EstateFlowchart/:flowchartId/RevocableTrust/:revTrustId/:revTrustSubPage">
                <RevocableTrustForm/>
            </Route>
        </Switch>
    )
}

export default EstateFlowchartMain;