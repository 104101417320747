import React, { useState, useEffect } from "react";
import FormHeader from "./components/FormHeader";
import { AlertMessage, Button } from "src/components";
import { Route, Switch, useHistory, useParams } from "react-router-dom";
import { RouteWithId, RouteWithIdAndFlowchartId } from "../routes/types";
import BasicInformationContent from "./components/BasicInformationContent";
import { RevocableTrust } from "./EstateFlowchartTypes";
import { estateFlowchartApiClient } from "./EstateFlowchartApiClient";
import { validateTrustResponse } from "./components/validations";
import { displayName } from "react-quill";
import BeneficiariesFirstDeathContent from "./components/BenefeciariesFirstDeathContent";

type AddRevocableTrustProps = {};

const subNavigation = [
  {
    name: "BasicInformation",
    displayName: "Basic Information",
  },
  {
    name: "BeneficiariesAtFirstDeath",
    displayName: "Beneficiaries at First Death",
  },
];

const RevocableTrustForm: React.FC<AddRevocableTrustProps> = () => {
  const history = useHistory();
  const { id } = useParams<RouteWithId>();
  const [showErrorBanner, updateShowErrorBanner] = useState(false);
  const [saveHasBeenClicked, updateSaveHasBeenClicked] = useState(false);
  const [revocableTrust, updateRevocableTrust] = useState({} as RevocableTrust);
  const [isNextDisabled, setNextDisabled] = useState(false);
  const [isPrevDisabled, setPrevDisabled] = useState(false);
  const { flowchartId } = useParams<RouteWithIdAndFlowchartId>();
  const { revTrustId } = useParams<RouteWithIdAndFlowchartId>();
  const [activeView, setActiveView] = useState(history.location.pathname.split('/').pop() as String);
  const headerTitle =
    flowchartId === "new" ? "Add Revocable Trust" : "Edit Revocable Trust";

  const RevocableTrustAlert = () => {
    return (
      <div className="row-container">
        <AlertMessage
          className="display-flex justify-content-right alertBanner"
          id="alertBanner"
          fullWidth={false}
          icon="warning"
          showAlert={true}
          showCloseBtn={false}
          type="error"
        >
          <b className="alertBannerStyleName">Required fields missing.</b>
          &nbsp;
          <b className="font-weight-400">
            All required fields must be entered to save a flowchart.
          </b>
        </AlertMessage>
      </div>
    );
  };

  useEffect(() => {
    const isLastView = activeView === subNavigation[subNavigation.length - 1].name;
    const isFirstView = activeView === subNavigation[0].name;
    setNextDisabled(isLastView);
    setPrevDisabled(isFirstView);
  }, [activeView]);

  const checkFormIsValid = (page: string = "") => {
    const profileValidation = validateTrustResponse(
      revocableTrust as RevocableTrust
    );
    const isValid = Object.values(profileValidation).every((x) => x === null);
    updateShowErrorBanner(!isValid);
    return isValid;
  };

  const handleCreateNewTrust = async (): Promise<boolean> => {
    /**
     * API POST to create new trust;
     */
    await estateFlowchartApiClient.createRevocableTrust(id, revocableTrust);
    return true;
  };

  const handleEditTrust = async (): Promise<boolean> => {
    await estateFlowchartApiClient.editRevocableTrust(
      id,
      flowchartId,
      revocableTrust
    );
    return true;
  };

  const onSave = async () => {
    if (checkFormIsValid()) {
      let handleSave;
      if (flowchartId === "new") {
        handleSave = handleCreateNewTrust;
      } else {
        handleSave = handleEditTrust;
      }
      handleSave().then((data) => {
        if (data) {
          /*Perform actions after the SAVE call**/
          history.push({
            pathname: `/Profile/${id}/ClientProfile/EstateFlowchart`,
          });
        }
      });
    } else {
      updateShowErrorBanner(true);
      updateSaveHasBeenClicked(true);
    }
  };
  // TODO: add check if form valid to prev and next (form validation needs to be refactored to fit the page we're on)
  const onPrevClick = () => {
      const currentIndx = subNavigation.findIndex(item => item.name == activeView)
      setActiveView(subNavigation[currentIndx - 1].name);
      history.push(
        `/Profile/${id}/ClientProfile/EstateFlowchart/${flowchartId}/RevocableTrust/${revTrustId}/` +
        subNavigation[currentIndx - 1].name
      )
  }

  const onNextClick = () => {
      const currentIndx = subNavigation.findIndex(item => item.name == activeView)
      setActiveView(subNavigation[currentIndx + 1].name);
      history.push(
        `/Profile/${id}/ClientProfile/EstateFlowchart/${flowchartId}/RevocableTrust/${revTrustId}/` +
        subNavigation[currentIndx + 1].name
      )
  }

  // TODO: missing test
  const handleMenuClick = (view: String) => {
    setActiveView(view);
    history.push(
      `/Profile/${id}/ClientProfile/EstateFlowchart/${flowchartId}/RevocableTrust/${revTrustId}/` +
      view
    );
    return true;
  };

  const activateMenuItem = (view: String) => {
    return view === activeView ? "sub-nav-item--active" : "sub-nav-item";
  };

  return (
    <div className="revocable-trust-form-container">
      <FormHeader title={headerTitle} />
      <div className="revocable-trust-content">
        <div className="sub-nav-container">
          <ul className="sub-nav-list" data-testid={"sub-nav-list"}>
            {subNavigation.map((item, index) => (
              <li
                className={activateMenuItem(item.name)}
                onClick={() => handleMenuClick(item.name)}
                key={index}
                data-testid={"sub-nav-list-item-" + item.name}
              >
                {item.displayName}
              </li>
            ))}
          </ul>
        </div>
        <div className="revocable-trust-form">
          {showErrorBanner && RevocableTrustAlert()}
          <div
            className={
              showErrorBanner
                ? "form-container form-container-error"
                : "form-container"
            }
          >
            <Switch>
              <Route path="/Profile/:id/ClientProfile/EstateFlowchart/:flowchartId/RevocableTrust/:revTrustId/BasicInformation">
                <BasicInformationContent
                  saveHasBeenClicked={saveHasBeenClicked}
                  updatedRevocableTrust={(newValue: RevocableTrust) =>
                    updateRevocableTrust(newValue)
                  }
                  id={id}
                />
              </Route>
              <Route path="/Profile/:id/ClientProfile/EstateFlowchart/:flowchartId/RevocableTrust/:revTrustId/BeneficiariesAtFirstDeath">
                <BeneficiariesFirstDeathContent />
              </Route>
            </Switch>
          </div>
        </div>
      </div>
      <div className="form-footer textalign-right" data-testid="revtrust-form-footer">
        <div className="prev-btn-container">
          <Button
            icon="none"
            id="prev_page_button"
            includeRef={false}
            kind="secondary"
            onClick={onPrevClick}
            size="medium"
            tabIndex={0}
            disabled={isPrevDisabled}
            type="button"
          >PREVIOUS</Button>
        </div>
        <div className="save-next-btn-container">
          <Button
            icon="none"
            id="save_new_client_profile_button"
            includeRef={false}
            kind="secondary"
            onClick={onSave}
            size="medium"
            tabIndex={0}
            type="button"
          >
            SAVE
          </Button>
          <Button
            icon="none"
            id="next_page_button"
            kind="primary"
            onClick={onNextClick}
            disabled={isNextDisabled}
            size="medium"
            tabIndex={1}
            type="button"
          >
            NEXT
          </Button>
        </div>
      </div>
    </div>
  );
};

export default RevocableTrustForm;
