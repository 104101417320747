import React, {ReactElement, useEffect, useState} from "react";
import {Col, Grid, Row} from "xps-react";
import {RouteWithId} from "../../../routes/types";
import {useHistory, useParams} from "react-router-dom";
import {getTodayDateAsString, toDisplayDateFormat} from "../../../utils/dateUtils";
import {ProfileDetailsProposals} from "./ProfileDetailsProposals";
import useProfileAndProposals from "../../../hooks/useProfileAndProposals";
import {MeetingActions} from "../../Meeting/MeetingActions";
import {Button, DropdownItem} from "../../../components";
import usePageViewTimer from "../../../hooks/usePageViewTimer";
import {ProfileUpdates} from "./ProfileUpdates";
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {selectReleaseToggles} from "../../../ReleaseToggles/releaseTogglesSlice";
import {setHeaderTickerTabs} from "../HeaderTickerTabsSlice";
import {EMPTY_HEADER_TICKER_TABS_RESPONSE} from "../HeaderTickerTabType";
import {resetShowOutOfEstateAssets} from "../../../Assets/common/OutOfEstate/OutOfEstateAssetsSlice";
import {resetAccordionPreferencesState} from "../../../Assets/common/accordionPreferencesSlice";
import {ProfileDetailsActionMenu} from "./ProfileDetailsActionMenu";
import {clientManagementApiClient} from "../../ClientManagementApiClient";
import {
    ProfileAwareActivatePortfolioReserveSwitch
} from "../../../Goals/PortfolioReserve/ActivatePortfolioReserve/ProfileAwareActivatePortfolioReserveSwitch";
import CustomModal from "../../../components/Modal/Custom/CustomModal";
import {LabelPosition} from "../../../Goals/PortfolioReserve/ActivatePortfolioReserve/ActivatePortfolioReserveSwitch";
import {setOldServiceTeam} from "../../../Resources/clientMeetingDocumentSlice";
import {emptyServiceTeam} from "../../../Resources/ResourcesConstant";
import {wealthManagementApiClient} from "../../WealthManagementApiClient";
import {patchProfile, selectProfile} from "../activeProfileSlice";
import {patchApprovedProfile} from "../approvedProfileSlice";
import {patchProposals} from "../../Proposals/proposalsSlice";
import useProfileEditableState from "../../../hooks/useProfileEditableState";
import { useAppInsights } from "src/AppInsights";
import {shortenName} from "../../../Assets/common/textUtils";

const ProfileDetails: React.FC = () => {
    const dispatch = useAppDispatch();
    const history = useHistory();
    const {id} = useParams<RouteWithId>();
    const {profile, proposals, isLoading} = useProfileAndProposals(id);
    const releaseToggles = useAppSelector(selectReleaseToggles);
    const profileFromState = useAppSelector(selectProfile)
    const [openArchiveProfileModal, setOpenArchiveProfileModal] = useState(false);
    const [openConvertToClientModal, setOpenConvertToClientModal] = useState(false);
    const {isArchiveReadOnly, isProfileWithProposalsReadOnly} = useProfileEditableState();
    const portfolioReserveActivationDate = profileFromState.portfolioReserveActivationDate === null ? getTodayDateAsString() : profile.portfolioReserveActivationDate
    const [statePRActivationDate, setStatePRActivationDate] = useState(portfolioReserveActivationDate === '' ? getTodayDateAsString() : portfolioReserveActivationDate)

    usePageViewTimer('Profile Details Page Load Timer (milliseconds)', isLoading);

    useEffect(() => {
        dispatch(resetAccordionPreferencesState(id));
        dispatch(resetShowOutOfEstateAssets());
    });

    let profileType;
    let profileTypePathName: string;
    if (profile.prospect) {
        profileType = "prospect";
        profileTypePathName = "Prospect";
    } else if (profile.sample) {
        profileType = "sample";
        profileTypePathName = "Sample";
    } else {
        profileType = "client";
        profileTypePathName = "Client";
    }

    const handleViewProfileNavigation = () => {
        let familyTreePage = `/Profile/${id}/ClientProfile/FamilyTree`;
        let hasActiveProposals = proposals?.filter(p => !p.archived).length > 0;
        if (!isArchiveReadOnly && hasActiveProposals) familyTreePage += "?readOnly=true";
        else if (isArchiveReadOnly) familyTreePage += "?archived=true"
        history.push(familyTreePage);
    };

    const handleProfileSettingsNavigation = () => {
        history.push(`/Profile/${id}/ProfileSettings`);
    };

    const handleNewProposalSettingsNavigation = () => {
        history.push(`/Profile/${id}/NewProposalSettings`);
    };

    const handleViewArchivedProposalsNavigation = () => {
        history.push(`/Profile/${id}/ViewArchivedProposals`);
    };

    const handleArchivingProfileAndDashboardNavigation = async () => {
        setOpenArchiveProfileModal(false)
        await clientManagementApiClient.putProfile({...profile, archived: true, activeClient: false})
            .catch(error => console.error('Could not archive profile', error.message));
        history.push(`/Dashboard/${profileTypePathName}`);
    };

    const appins = useAppInsights();
    const handleConvertToClient = () => {
        setOpenConvertToClientModal(false);
        clientManagementApiClient.convertProspect(profile.id).then(() => {
            appins.trackEvent({
                name: 'ProspectConversion',
                properties: {
                    action: 'Prospect Converted',
                    profileId: profile.id,
                    data: 'Prospect converted to client in UI'
                }
            });
            history.push(`/`);
        }).catch((error) => {
            console.error('Could not convert prospect to client', error.message);
        })
    }

    const handleRestoreProfile = (path : String) => {
        clientManagementApiClient.putProfile({
            ...profile,
            archived: false,
            activeClient: true
        }).then(() => {
            history.push(path);
        });
    }

    const handleProposalSettingsNavigation = (proposalId: string) => {
        history.push(`/Profile/${proposalId}/ProposalSettings/${id}`);
    }

    useEffect(() => {
        dispatch(setHeaderTickerTabs(EMPTY_HEADER_TICKER_TABS_RESPONSE));
        dispatch(setOldServiceTeam(emptyServiceTeam));
    }, [])

    const handleTogglePortfolioReserve = (activePortfolioReserve: boolean) => {
        activePortfolioReserve ? setStatePRActivationDate(getTodayDateAsString()) : setStatePRActivationDate(null)

        wealthManagementApiClient.toggleActivatePortfolioReserve(
            profile.id,
            activePortfolioReserve
        ).then(() => {
            try {
                dispatch(patchProfile({activePortfolioReserve}));
                dispatch(patchApprovedProfile({activePortfolioReserve}));
                dispatch(patchProposals({activePortfolioReserve}));
            } catch (error) {
                console.error('Could not activate portfolio reserve toggle', error);
            }
        })
    };
    const actionMenuDropdownItems: ReactElement[] = [];

    if (profile.archived) {
        if (profile.prospect) {
            actionMenuDropdownItems.push(
                <DropdownItem
                    key={`restoreProspect ${profile.id}`}
                    aria-label="restoreProspect"
                    onClick={() => {
                        handleRestoreProfile(`/Dashboard/Prospect`);
                    }}
                    itemText={"Restore Prospect"}
                    value="RestoreProspect"
                />
            );
        } else if (profile.sample) {
            actionMenuDropdownItems.push(
                <DropdownItem
                    key={`restoreSample ${profile.id}`}
                    aria-label="restoreSample"
                    onClick={() => {
                        handleRestoreProfile(`/Dashboard/Sample`)
                    }}
                    itemText={"Restore Sample"}
                    value="RestoreSample"
                />
            );
        } else {
            actionMenuDropdownItems.push(
                <DropdownItem
                    key={`restoreClient ${profile.id}`}
                    aria-label="restoreClient"
                    onClick={() => {
                        handleRestoreProfile(`/`)
                    }}
                    itemText={"Restore Client"}
                    value="RestoreClient"
                />
            );
        }
    } else {
        if (profile.prospect && profile.profileIdForProposal === null) {
            actionMenuDropdownItems.push(
                <DropdownItem
                    key={"convertToClient"}
                    aria-label={"convertToClient"}
                    onClick={() => {
                        setOpenConvertToClientModal(true);
                    }
                    }
                    itemText={"Convert to Client"}
                    value={"ConvertToClient"}
                />)
        }
        actionMenuDropdownItems.push(<DropdownItem
            key={`archiveClient`}
            aria-label="archiveClient"
            className="viewClientDetails"
            onClick={() => {
                setOpenArchiveProfileModal(true)
            }}
            itemText={`Archive ${profileType[0].toUpperCase() + profileType.slice(1)}`}
            value="ArchiveClient"
        />);
    }

    return (
        <>
            <CustomModal isOpen={openArchiveProfileModal}
                         title={`Archive this ${profileType}?`}
                         content={`Are you sure you want to archive the selected ${profileType}?`}
                         onClickCancel={() => setOpenArchiveProfileModal(false)}
                         onClickConfirm={() => handleArchivingProfileAndDashboardNavigation()}
                         cancelText='CANCEL'
                         confirmText={`ARCHIVE ${profileType.toUpperCase()}`}
            />

            <CustomModal isOpen={openConvertToClientModal}
                         title={'Convert to Client Profile?'}
                         content={'Are you sure you want to convert this Prospect Profile to a Client Profile? This profile will be moved to the Active Client List on the Partner Dashboard. ' +
                             'To provide access to the Service Team, a PW Client Profile must be linked via Profile Settings.'}
                         onClickCancel={() => setOpenConvertToClientModal(false)}
                         onClickConfirm={() => handleConvertToClient()}
                         cancelText='CANCEL'
                         confirmText={'CONVERT TO CLIENT'}
            />

            <Grid fluid={true}>
                <Row>
                    <Col>
                        <div className="page-container profile-details-container">
                            <div className="profile-details-cta">
                                <div className="h1">{shortenName(profile?.displayName,50)}</div>
                                <div className="right-aligned">
                                    <ProfileDetailsActionMenu
                                        dropdownItems={actionMenuDropdownItems}
                                    />
                                    <Button
                                        id="NavigateToProfileSettings"
                                        className="marginleft-sm marginright-sm"
                                        icon="none"
                                        iconName="settings"
                                        includeRef={false}
                                        kind="secondary"
                                        size="medium"
                                        tabIndex={0}
                                        type="button"
                                        onClick={handleProfileSettingsNavigation}
                                    >
                                        <span className="new-client-text">PROFILE SETTINGS</span>
                                    </Button>
                                    <Button
                                        id="NavigateToFamilyTree"
                                        className="marginleft-sm"
                                        icon="none"
                                        iconName="settings"
                                        includeRef={false}
                                        kind="primary"
                                        size="medium"
                                        tabIndex={0}
                                        type="button"
                                        onClick={handleViewProfileNavigation}
                                    >
                                    <span
                                        className="new-client-text">{proposals && proposals.length <= 0 ? 'EDIT PROFILE' : 'VIEW PROFILE'}</span>
                                    </Button>
                                </div>
                            </div>
                            <span className="greyed-out">{
                                `Last updated ${toDisplayDateFormat(profile.lastModifiedDate)} by ${shortenName(profile.lastModifiedByName,50)}`
                            }</span>
                            {
                                releaseToggles?.enableActivePortfolioReserve &&
                                <span className="display-flex">
                                        <ProfileAwareActivatePortfolioReserveSwitch
                                            className="display-flex align-items-center"
                                            labelPosition={LabelPosition.Left}
                                            onTogglePortfolioReserve={handleTogglePortfolioReserve}
                                        />
                                    {profileFromState.activePortfolioReserve &&
                                        <div data-testid="activate-pr-date"
                                             className="marginleft-lg align-self-end color-text--lightgrey">
                                            Activated {toDisplayDateFormat(statePRActivationDate)}
                                        </div>
                                    }
                                    </span>
                            }
                            <ProfileDetailsProposals profileId={profile.id}
                                                     onCreateNewProposal={handleNewProposalSettingsNavigation}
                                                     onUpdateProposal={handleProposalSettingsNavigation}
                                                     onViewArchivedProposals={handleViewArchivedProposalsNavigation}
                                                     proposals={proposals}
                                                     isLoading={isLoading}
                                                     isArchiveReadOnly={isArchiveReadOnly}
                                                     isProfileWithProposalsReadOnly={isProfileWithProposalsReadOnly}
                            />

                            {releaseToggles?.enableProfileAssistant ?
                                <section className="">
                                    <ProfileUpdates profileId={profile.id}/>
                                </section>
                                :
                                <React.Fragment/>
                            }
                        </div>
                    </Col>
                    <Col className="meeting-actions-section">
                        <MeetingActions/>
                    </Col>
                </Row>
            </Grid>
        </>
    );
};
export default ProfileDetails;
