import {
    DatePicker,
    Dropdown,
    DropdownItem,
    Input,
    RedAsterisk,
    RequiredFieldsSubheader,
    UnderlinedHeader
} from "../../components";
import React, {ChangeEvent, useEffect, useState} from "react";
import {DISPLAY_DATE_FORMAT} from "../../constants/common";
import moment, {Moment} from "moment";
import {EstateFlowchartState, FamilyMembers, Member, RevocableTrust, Trustee, TrusteeType} from "../EstateFlowchartTypes";
import {useAppDispatch, useAppSelector} from "src/store/hooks";
import {useParams} from "react-router-dom";
import {getEstateFlowcharts, setEstateFlowchartState} from "../EstateFlowChartSlice";
import {RouteWithIdAndFlowchartId} from "src/routes/types";
import {momentToISO} from "../../utils/dateUtils";
import {isCustomMember, isUUID, isValid, parseFamilyMembers} from "./validations";
import DropdownMultiselect from "./DropdownMultiselect";
import {clientManagementApiClient} from "../../ClientManagement/ClientManagementApiClient";
import SuccessorTable from "./SuccessorTable";
import {ReferenceDataType} from "../../models/referenceData/ReferenceDataType";
import {referenceDataClient} from "../../core/ReferenceDataClient";
import {MemberGroup} from "src/ClientManagement/models/InvestorGroupType";
import { estateFlowchartApiClient } from "../EstateFlowchartApiClient";

type BasicInformationContentProps = {
    saveHasBeenClicked: boolean,
    updatedRevocableTrust: Function,
    id: string
}

const initialMemberItem = {
    label: "", selected: false, value: ""
}

/**
 * Component for rendering the basic information content of an estate flowchart.
 *
 * @component
 * @param {BasicInformationContentProps} props - The properties for the component.
 * @param {boolean} props.saveHasBeenClicked - Indicates if the save button has been clicked.
 * @param {Function} props.updatedRevocableTrust - Function to update the revocable trust.
 * @param {string} props.id - The ID of the estate flowchart.
 *
 * @returns {JSX.Element} The rendered component.
 *
 * @example
 * <BasicInformationContent
 *   saveHasBeenClicked={true}
 *   updatedRevocableTrust={handleUpdateRevocableTrust}
 *   id="12345"
 * />
 *
 * @remarks
 * This component handles the display and update of basic information related to a revocable trust.
 * It includes fields for flowchart name, legal name, nickname, date created, record of amendments,
 * grantors, trustees, funding, investment advisor, trust protector, and trust jurisdiction.
 *
 * The component uses various hooks such as `useState`, `useEffect`, and custom hooks like `useAppSelector`.
 * It also interacts with APIs to fetch family members and reference data.
 *
 * The component includes validation for required fields and dynamically updates the state based on user input.
 */
const BasicInformationContent: React.FC<BasicInformationContentProps> = ({
                                                                             saveHasBeenClicked,
                                                                             updatedRevocableTrust,
                                                                             id
                                                                         }) => {

    const [basicInformation, updateBasicInformation] = useState({} as RevocableTrust);
    const estateFlowchartsInState = useAppSelector(getEstateFlowcharts);
    const {flowchartId} = useParams<RouteWithIdAndFlowchartId>();
    const [selectedGrantors, setSelectedGrantors] = useState([] as string[])
    const [grantors, setGrantors] = useState([] as Member[])
    const [selectedTrustees, setSelectedTrustees] = useState([] as string[])
    const [trustees, setTrustees] = useState([{label: "Northern Trust", selected: false, value: ""}] as Member[])
    const [familyMembersResponse, setFamilyMembersResponse] = useState({} as FamilyMembers);
    const dropdownHeight: number = grantors.length * 34 < 280 ? (grantors.length * 34) + 20 : 280
    const [states, updateStates] = useState([] as any[]);
    const dispatch = useAppDispatch();

    const buildMembers = (trust: RevocableTrust, type: keyof RevocableTrust) => {
        const selectedCustomMembers: Member[] = (trust[type] as Trustee[])?.filter(member => !member.familyMember).map(member => ({
            label: member.customName || "",
            selected: true,
            value: member.memberId || ""
        })) || [];
    
        const selectedMembers: string[] = (trust[type] as Trustee[])?.map(trustee => trustee.memberId || "") || [];
    
        switch (type) {
            case "grantors":
                setSelectedGrantors(selectedMembers);
                setGrantors(prevArray => [...prevArray, ...selectedCustomMembers]);
                break;
            case "trustees":
                const hasNorthernTrust = (trust[type] as Trustee[])?.some(member => member.customName === "Northern Trust");
                setTrustees(prevArray => hasNorthernTrust 
                    ? [...prevArray.filter(member => member.label !== "Northern Trust"), ...selectedCustomMembers]
                    : [...prevArray, ...selectedCustomMembers]
                );
                setSelectedTrustees(selectedMembers);
                break;
            default:
        }
    }
    const getFlowchart = async (): Promise<EstateFlowchartState[]> => {
        return await estateFlowchartApiClient.getFlowchart(id);
    }
    
    useEffect(() => {
        updatedRevocableTrust(basicInformation)
    }, [basicInformation]);

    const getFamilyMembers = async (): Promise<MemberGroup> => {
        return clientManagementApiClient.getMemberGroup(id);
    }

    const buildInitialForm = () => {
        const initializeTrust = (trust: RevocableTrust | undefined) => {
            if (trust) {
                updateBasicInformation(trust);
                buildMembers(trust, "grantors");
                buildMembers(trust, "trustees");
            }
        };

        if (estateFlowchartsInState.length > 0) {
            const trust = estateFlowchartsInState.find(flowchart => flowchart.flowchartId === flowchartId)?.revocableTrust;
            initializeTrust(trust);
        } else {
            getFlowchart().then((response) => {
                dispatch(setEstateFlowchartState(response));
                const trust = response.find(flowchart => flowchart.flowchartId === flowchartId)?.revocableTrust;
                initializeTrust(trust);
            });
        }
    };

    useEffect(() => {
        let isMounted = true;
        getFamilyMembers().then((res) => {
            const parsedFamilyMembersResponse = parseFamilyMembers(res);
            if (isMounted) setFamilyMembersResponse(parsedFamilyMembersResponse);
            let item = initialMemberItem;
            item.label = parsedFamilyMembersResponse.label;
            item.value = parsedFamilyMembersResponse.value;
            parsedFamilyMembersResponse.familyMember.push(item);
            if (isMounted) setGrantors(prevArray => [...prevArray, ...parsedFamilyMembersResponse.familyMember]);
            if (isMounted) setTrustees(prevArray => [...prevArray, ...parsedFamilyMembersResponse.familyMember]);
        })

        referenceDataClient.getReferenceData()
            .then((data: ReferenceDataType) => {
                const statesOfResidency: any = data.statesOfResidency
                let listOfStates: {itemText: string, value: string}[] = [];
                for (const key in statesOfResidency) {
                    let item: any = {};
                    item.value = key;
                    item.itemText = statesOfResidency[key];
                    listOfStates.push(item);
                }
                listOfStates.sort((a, b) => a.itemText.localeCompare(b.itemText))
                if (isMounted) updateStates(listOfStates);
                buildInitialForm()
            })
            .catch(() => {
                buildInitialForm()
            })
        return () => {
            isMounted = false;
        }
    }, []);

    const onChangeDropdown = (selectedOptions: any, type: TrusteeType) => {
        const members: Trustee[] = [];
        for (const element of selectedOptions) {
            const member: Trustee = {revTrustId: "", memberType: type, familyMember: true, customName: ""};
            member.memberType = type;
            member.revTrustId = basicInformation.revTrustId;
            if (element.hasOwnProperty("icon")) { // only for new custom
                member.customName = element.label;
                member.familyMember = false;
            } else { // family members and previous custom
                member.familyMember = !isCustomMember(element.value, familyMembersResponse);
                member.memberId = element.value;
                if (isCustomMember(element.value, familyMembersResponse)) {
                    member.customName = element.label;
                } else {
                    member.customName = "";
                }
            }
            members.push(member);
        }
        switch (type) {
            case TrusteeType.Grantor:
                updateBasicInformation({...basicInformation, grantors: members});
                break;
            case TrusteeType.Trustee:
                updateBasicInformation({...basicInformation, trustees: members});
                break;
            default:
        }
    }

    return (
        <>
            <section aria-label="Basic Info" className="basic-information-form">
                <h2 className={"fontweight-500 marginbottom-xl"}>Basic Information</h2>
                <UnderlinedHeader
                    leftAlignedContent={<div className="required-header" data-testid={'basicInfo-container'}><h4>Basic
                        Details</h4><RequiredFieldsSubheader/></div>}/>

                <div className="textalign-right form-main">
                    <div className="layout-data-entry-form__field">
                        <label className={"h5"} data-testid={'flowchart-name'}>Flowchart Name<RedAsterisk/></label>
                        <Input
                            aria-label="Flowchart Name"
                            size="medium"
                            value={basicInformation.flowchartName || ""}
                            error={saveHasBeenClicked && !isValid(basicInformation.flowchartName) ? "Flowchart Name is required." : undefined}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => updateBasicInformation({
                                ...basicInformation, flowchartName: e.target.value
                            })}
                        />
                    </div>
                    <div className="layout-data-entry-form__field">
                        <label className={"h5"} data-testid={'legal-name'}>Legal Name of Trust or
                            Entity<RedAsterisk/></label>
                        <Input
                            aria-label="Legal Name"
                            size="medium"
                            value={basicInformation.legalName || ''}
                            error={saveHasBeenClicked && !isValid(basicInformation.legalName) ? "Legal Name of Trust or Entity is required." : undefined}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => updateBasicInformation({
                                ...basicInformation, legalName: e.target.value
                            })}

                        />
                    </div>
                    <div className="layout-data-entry-form__field">
                        <label className={"h5"} data-testid={'nick-name'}>Nickname</label>
                        <Input
                            aria-label="Nickname"
                            size="medium"
                            value={basicInformation.nickName ? basicInformation.nickName : ''}
                            disabled={false}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => updateBasicInformation({
                                ...basicInformation, nickName: e.target.value
                            })}
                        />
                    </div>
                    <div className="layout-data-entry-form__field">
                        <label className={"h5"} data-testid={'date-created'}>Date Created<RedAsterisk/></label>
                        <DatePicker
                            className="createdDateField"
                            id="createdDateInput"
                            aria-label="Date Created"
                            displayFormat={DISPLAY_DATE_FORMAT}
                            hideKeyboardShortcutsPanel
                            //Todo: missing test
                            isOutsideRange={(date: Moment) => date.isAfter(moment().endOf('day'))}
                            date={basicInformation.trustCreationDate ? moment(basicInformation.trustCreationDate) : undefined}
                            error={saveHasBeenClicked && !isValid(basicInformation.trustCreationDate) ? "Date Created is required." : undefined}
                            onDateChange={
                                (date: Moment) => {
                                    updateBasicInformation({
                                        ...basicInformation, trustCreationDate: date !== null ? momentToISO(date) : ""
                                    })
                                }
                            }
                        />
                    </div>
                    <div className="layout-data-entry-form__field">
                        <div className={"textarea-label"}><label className={"h5"} data-testid={'record-of-amendments'}>Record
                            of Amendments</label>
                            <span>{basicInformation?.recordOfAmendment ? basicInformation?.recordOfAmendment?.length : 0}/500</span>
                        </div>
                        <textarea
                            name="Record of Amendments"
                            className="record-of-amendments input-skin"
                            data-testid='record-of-amendments-field'
                            autoFocus={false}
                            rows={5}
                            cols={50}
                            maxLength={500}
                            defaultValue={basicInformation.recordOfAmendment ? `${basicInformation.recordOfAmendment}` : ''}
                            onChange={(e) => {
                                updateBasicInformation({
                                    ...basicInformation, recordOfAmendment: e.target.value
                                })
                            }
                            }
                        />
                    </div>
                </div>
            </section>
            <section aria-label="Trust Details" className="trust-details-form">

                <UnderlinedHeader
                    leftAlignedContent={<div className="required-header" data-testid={'trustDetails-container'}>
                        <h4>Trust Details</h4> <RequiredFieldsSubheader/></div>}/>
                <div className="layout-data-entry-form__field" data-testid={'grantors-dropdown'}>
                    <label className={"h5"}>Grantors<RedAsterisk/></label>
                    <DropdownMultiselect
                        className={'dds-ms'}
                        alignRight={false}
                        creatable
                        disabled={false}
                        dropUp={false}
                        dynamicDropAlign={false}
                        dynamicDropDirection={false}
                        error={saveHasBeenClicked && (!basicInformation.grantors || basicInformation.grantors.length === 0) ? "Grantor is required." : undefined}
                        hideSelectAll
                        id="DDMS-creatable"
                        label=""
                        onChange={(options: String[]) => {
                            onChangeDropdown(options, TrusteeType.Grantor)
                        }}
                        open={false}
                        options={grantors}
                        panelHeight={grantors.length * 34 < 280 ? (grantors.length * 34) + 20 : 280}
                        panelWidth="100%"
                        placeholder=""
                        required={false}
                        searchable
                        selectAllLabel=""
                        selected={selectedGrantors}
                        selectedDisplayTextAfter=""
                        selectedDisplayTextBefore=""
                        showTotalSelected={false}
                        showClearAllButton={basicInformation.grantors && basicInformation.grantors.length > 1}
                        showFullSelected={true}
                        helperText="Add a new name, if it is not in the list."
                    />
                </div>

                <div className="layout-data-entry-form__field" data-testid={'trustees-dropdown'}>
                    <label className={"h5"} data-testid={'trustees'}>Trustees<RedAsterisk/></label>
                    <DropdownMultiselect
                        className={'dds-ms'}
                        alignRight={false}
                        creatable
                        disabled={false}
                        dropUp={false}
                        dynamicDropAlign={false}
                        dynamicDropDirection={false}
                        error={saveHasBeenClicked && (!basicInformation.trustees || basicInformation.trustees.length === 0) ? "Trustee is required." : undefined}
                        hideSelectAll
                        id="DDMS-creatable"
                        label=""
                        onChange={(options: String[]) => {
                            onChangeDropdown(options, TrusteeType.Trustee)
                        }}
                        open={false}
                        options={trustees}
                        panelHeight={trustees.length * 34 < 280 ? (trustees.length * 34) + 20 : 280}
                        panelWidth="100%"
                        placeholder=""
                        required={false}
                        searchable
                        selectAllLabel=""
                        selected={selectedTrustees}
                        selectedDisplayTextAfter=""
                        selectedDisplayTextBefore=""
                        showTotalSelected={false}
                        showClearAllButton={basicInformation.trustees && basicInformation.trustees.length > 1}
                        showFullSelected={true}
                        helperText="Add a new name, if it is not in the list."
                    />
                </div>
                {
                    familyMembersResponse.familyMember && familyMembersResponse.familyMember.length > 0 &&
                    <SuccessorTable basicInformation={basicInformation}
                                    dropdownHeight={dropdownHeight}
                                    familyMembersResponse={familyMembersResponse}
                                    updateBasicInformation={updateBasicInformation}/>
                }
                <div className="layout-data-entry-form__field basic-information__funding">
                    <div className={"textarea-label"}><label className={"h5"} data-testid={'funding'}>Funding</label>
                        <span>{basicInformation?.funding ? basicInformation?.funding?.length : 0}/500</span>
                    </div>
                    <textarea
                        name="Funding"
                        className="funding input-skin"
                        data-testid='funding-field'
                        autoFocus={false}
                        rows={5}
                        cols={50}
                        maxLength={500}
                        defaultValue={basicInformation.funding ? `${basicInformation.funding}` : ''}
                        onChange={(e) => {
                            updateBasicInformation({
                                ...basicInformation, funding: e.target.value
                            })
                        }
                        }
                    />
                </div>
                <div className="layout-data-entry-form__field">
                    <label className={"h5"} data-testid={'investment-advisor'}>Investment Advisor</label>
                    <Input
                        aria-label="Investment Advisor"
                        size="medium"
                        value={basicInformation.investmentAdvisor ? basicInformation.investmentAdvisor : ''}
                        disabled={false}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => updateBasicInformation({
                            ...basicInformation, investmentAdvisor: e.target.value
                        })}
                    />
                </div>
                <div className="layout-data-entry-form__field">
                    <label className={"h5"} data-testid={'nick-name'}>Trust Protector</label>
                    <Input
                        aria-label="Trust Protector"
                        size="medium"
                        value={basicInformation.trustProtector ? basicInformation.trustProtector : ''}
                        disabled={false}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => updateBasicInformation({
                            ...basicInformation, trustProtector: e.target.value
                        })}
                    />
                </div>
                <div className="layout-data-entry-form__field basic-information__trust-jurisdiction"
                     data-testid={'trust-jurisdiction-dropdown'}>
                    <label className={"h5"} data-testid={'trust-jurisdiction'}>Trust Jurisdiction</label>
                    <Dropdown
                        className="trust-jurisdiction-field"
                        name="trustJurisdiction"
                        id="trustJurisdiction"
                        aria-label="trustJurisdiction"
                        aria-labelledby="trustJurisdiction-label"
                        alignRight={false}
                        buttonIcon="right"
                        buttonKind="secondary"
                        defaultPageSize={15}
                        dropdownKind="select"
                        dynamicDropAlign={true}
                        dynamicDropDirection={true}
                        iconNameClose="arrow_drop_up"
                        iconNameOpen="arrow_drop_down"
                        nativeOnMobile={false}
                        panelHeight={232}
                        panelWidth="100%"
                        searchable
                        defaultText="Select..."
                        onChange={(e: any) => updateBasicInformation({
                            ...basicInformation, trustJurisdiction: e.value
                        })}
                        size="medium"
                        virtualScroll={false}
                        value={basicInformation.trustJurisdiction ? basicInformation.trustJurisdiction : ''}
                    >
                        {states.map(state => (
                            <DropdownItem key={state.value} itemText={state.itemText} value={state.value}/>
                        ))}
                    </Dropdown>
                </div>
            </section>
        </>
    );
}

export default BasicInformationContent;