type BeneficiariesFirstDeathContentProps = {
}

const BeneficiariesFirstDeathContent: React.FC<BeneficiariesFirstDeathContentProps> = () => {
    return (
        <div className="beneficiary-first-death-form">
            <div className="header">
                <h1>Beneficiaries at First Death</h1>
                <h6>Begin by adding new trust or distributions to be created upon death of first grantor</h6>
            </div>
        </div>
    )
}

export default BeneficiariesFirstDeathContent;