import {RevocableTrust, TrustValidation, Member, FamilyMembers} from "../EstateFlowchartTypes";
import { FamilyRelationship } from "src/ClientManagement/models/FamilyRelationship";
import { MemberGroup } from "src/ClientManagement/models/InvestorGroupType";

export const isValid = (value:  String) => {
    return !!(value && value.replace(/\s/g, '').length > 0);
}
export const validateTrustResponse = (trust: RevocableTrust): TrustValidation => {
    const revocableTrustValidation = {
        flowchartName: isValid(trust.flowchartName) ? null : "Flowchart Name is required.",
        legalName: isValid(trust.legalName) ? null : "Legal name is required",
        grantors: trust.grantors && trust.grantors.length > 0 ? null : "Grantors required",
        trustees: trust.trustees && trust.trustees.length > 0 ? null : "Trustees required",
        dateCreated: isValid(trust.trustCreationDate) ? null : "Date Created is required",
    }
    const revocableTrustHasErrors = Object.values(revocableTrustValidation).some((validation) => validation !== null)

    return (
        {revocableTrust: revocableTrustHasErrors ? revocableTrustValidation : null}
    );
}

export const parseFamilyMembers = (data: MemberGroup): FamilyMembers => {
	const value = data.primaryMember.id;
	const label = data.primaryMember.firstName + " " + data.primaryMember.lastName;
	let familyMembers: Member[] = data.additionalMembers.flatMap((member: any) => {
		return [
			{
				value: member.id,
				label: member.firstName + " " + member.lastName,
				selected: false
			}
		];
	})
		if (data.partnerMember) {
		familyMembers.push({
			value: data.partnerMember.id,
			label: data.partnerMember.firstName + " " + data.partnerMember.lastName,
			selected: false
		});
	}

	return { value, label, familyMember: familyMembers };
}


export const isUUID = (text: string) => {
	const regex = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
	return regex.test(text);
}

export const isCustomMember = (member_id: string, familyMembers: FamilyMembers) => {
	// compare member_id against list of member_id's from the family tree
	const memberIds = familyMembers.familyMember.map(member => member.value);
	return !memberIds.includes(member_id);
}